<script>
export default {
  name: "add",
  data: () => ({
    operationType: [
      {
        text: "إيداع",
        value: 1,
      },
      {
        text: "سحب",
        value: 2,
      },
      {
        text: "أرباح",
        value: 3,
      },
    ],
    requestDto: {
      operationType: null,
      amount: 0,
      notes: null,
      receiverName: null,
      userId: null,
      transactionDate: null,
    },
    userId: null,
    dateText: null,
    isfullscreen: false,
    valid: true,
    dialog: false,
    loading: false,
    lengthUsers: 0,
    filterUser: {
      pageIndex: 1,
      pageSize: 15,
      search: null,
      count: 0,
    },
    loadingUsers: false,
    users: [],
    menu: false,
    activePicker: null,
  }),
  methods: {
    CleanFilterInvestor() {
      this.requestDto.userId = null;
      this.users = [];
      this.userId = null;
    },
    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    async getUsers(pageIndex, pageSize, search) {
      const isEmpty = this.isStringEmptyOrWhitespace(search);
      if (isEmpty) {
        return;
      }
      this.loadingUsers = true;
      this.users = [];
      try {
        let queryUsers = `Users?UserType=2`;
        if (pageIndex !== undefined && pageIndex !== null) {
          queryUsers = queryUsers + `&PageIndex=${pageIndex}`;
        }
        if (pageSize !== undefined && pageSize !== null) {
          queryUsers = queryUsers + `&PageSize=${pageSize}`;
        }
        if (search !== undefined && search !== null) {
          queryUsers += `&Search=${search}`;
        }
        const res = await this.$http.get(queryUsers);
        // console.log(res.data.result.data);
        this.users = res.data.result.data;
        this.filterUser.count = res.data.result.count;
        this.lengthUsers =
          res.data.result.count === undefined
            ? 0
            : Math.ceil(res.data.result.count / this.filterUser.pageSize);
      } catch (err) {
        console.log(err);
        // service.faild(err.response.data.message);
      } finally {
        this.loadingUsers = false;
      }
      // console.log(this.users,this.filterUser);
    },
    async handleSearchChange(newSearch, oldSearch) {
      // This function will be called whenever the 'search' property changes
      // console.log(`Search changed from ${oldSearch} to ${newSearch}`);
      // Introduce a delay of 1 second (1000 milliseconds) using setTimeout
      setTimeout(async () => {
        // console.log(newSearch, oldSearch, this.filterUser.search)
        // if (this.users.length > 0 && !!newSearch) {return}
        // await this.getUsers(this.filterUser.pageIndex, this.filterUser.pageSize,this.filterUser.search);
        // Your logic here (e.g., make an API call or update component state)
        // console.log('Delayed action after 1.1 second');
      }, 2100);
    },
    save(date) {
      this.dateText = date;
      this.menu = false;
    },
    clearDate() {
      this.dateText = null;
      this.requestDto.transactionDate = null;
    },
    isStringEmptyOrWhitespace(str) {
      // Check if the string is null, undefined, or contains only whitespace
      return !str || !str.trim();
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.$http
        .post(`${this.$route.meta.endPoint}`, this.requestDto)
        .then((res) => {
          this.$emit(`refresh`);
          this.$store.dispatch("toastification/setToast", {
            message: `${res.data.message}`,
            type: "success",
          });
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
          this.dialog = false;
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("toastification/setToast", {
            message: `${err.response.data.message}`,
            type: "error",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
  watch: {
    "filterUser.search": {
      handler: "handleSearchChange",
      deep: true,
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    formattedAmount() {
      // Format the amount as IQD currency
      const formatter = new Intl.NumberFormat("ar-IQ", {
        style: "currency",
        currency: "IQD",
      });
      return formatter.format(this.requestDto.amount);
    },
  },
};
</script>

<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="50%"
      :fullscreen="isfullscreen"
      persistent
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" class="btn">
          <v-icon small class="pl-1">mdi-plus</v-icon>
          أضافة جديد
        </v-btn>
      </template>
      <v-card flat class="px-4 pt-4">
        <!-- dialog toolbar -->
        <v-toolbar height="45" flat>
          <h5>إضافة أستثمار جديد</h5>
          <v-spacer />
          <v-btn small icon fab @click="isfullscreen = !isfullscreen">
            <v-icon small>{{
              isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand"
            }}</v-icon>
          </v-btn>
          <v-btn @click="dialog = false" fab small icon color="grey">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="mx-4" />

        <!-- form body -->
        <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid">
          <v-container>
            <v-card flat class="py-4 px-1">
              <v-row>
                <!-- notes -->
                <v-col cols="12" md="12">
                  <h6 class="mb-1">ملاحظات <span class="required">*</span></h6>
                  <v-textarea
                    style="max-height: 600px"
                    v-model="requestDto.notes"
                    required
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    placeholder="الملاحظات"
                  >
                  </v-textarea>
                </v-col>
                <!-- amount -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المبلغ <span class="required">*</span></h6>
                  <v-text-field
                    v-model="requestDto.amount"
                    class="custom-font-size"
                    :hint="formattedAmount"
                    persistent-hint
                    :rules="[$global.state.required()]"
                    required
                    outlined
                    hide-details="auto"
                    dense
                    filled
                    type="number"
                    placeholder="المبلغ"
                  >
                  </v-text-field>
                </v-col>
                <!-- select user -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المستخدمين <span class="required">*</span></h6>
                  <!--                                        :search-input.sync="filterUser.search"-->
                  <v-autocomplete
                    @update:search-input="
                      getUsers(
                        filterUser.pageIndex,
                        filterUser.pageSize,
                        filterUser.search
                      )
                    "
                    :search-input.sync="filterUser.search"
                    :items="users"
                    :loading="loadingUsers"
                    v-model="requestDto.userId"
                    item-text="fullName"
                    :rules="[$global.state.required()]"
                    item-value="id"
                    clearable
                    no-filter
                    @click:clear="CleanFilterInvestor()"
                    dense
                    filled
                    label="اكتب اسم المستخدم او الاسم الكامل او الرقم للبحث"
                    placeholder="المستخدم"
                    hint="اكتب اسم المستخدم او الاسم الكامل او الرقم للبحث"
                    persistent-hint
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  >
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-divider />
                        <v-pagination
                          v-model="filterUser.pageIndex"
                          :length="lengthUsers"
                          :total-visible="7"
                          @input="
                            getUsers(
                              filterUser.pageIndex,
                              filterUser.pageSize,
                              filterUser.search
                            )
                          "
                        ></v-pagination>
                      </v-list-item>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar
                        color="indigo"
                        class="text-h5 font-weight-light white--text"
                      >
                        {{ item.fullName.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.fullName }}</v-list-item-title>
                        <v-list-item-subtitle dir="auto">
                          {{ item.phoneNumber }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!-- type -->
                <v-col cols="12" md="6">
                  <h6 class="mb-1">نوع الاستثمار <span class="required">*</span></h6>
                  <v-autocomplete
                    v-model="requestDto.operationType"
                    item-text="text"
                    item-value="value"
                    :items="operationType"
                    clearable
                    dense
                    filled
                    :rules="[$global.state.required()]"
                    placeholder="نوع الاستثمار"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">المستلم <span class="required">*</span></h6>
                  <v-text-field
                    v-model="requestDto.receiverName"
                    clearable
                    dense
                    filled
                    placeholder="المستلم"
                    no-data-text="لا توجد بيانات"
                    hide-details
                    outlined
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <h6 class="mb-1">تاريخ العملية <span class="required">*</span></h6>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateText"
                        placeholder="تاريخ العملية "
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        dense
                        filled
                        no-data-text="لا توجد بيانات"
                        hide-details
                        clearable
                        @click:clear="clearDate()"
                        outlined
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="requestDto.transactionDate"
                      :active-picker.sync="activePicker"
                      min="1950-01-01"
                      @input="save(requestDto.transactionDate)"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-divider class="ml- mt-8" />
              <!-- actions -->
              <div class="mt-4">
                <v-btn
                  type="submit"
                  :loading="loading"
                  color="primary"
                  class="btn"
                  :disabled="!valid"
                >
                  حفظ
                  <v-icon small right>fi fi-rr-disk</v-icon>
                </v-btn>
                <v-btn @click="close()" text depressed class="mr-2 btn">إلغاء</v-btn>
              </div>
            </v-card>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.custom-font-size >>> .v-messages__message {
  font-size: 18px !important;
  color: black;
  /* Adjust the font size as needed */
}
</style>
